// CONSTANTS
const env = "production";
const apiUrl = {
  local: "http://0.0.0.0:9000/api",
  development: "https://preapi.admindinder.com/api",
  production: "https://api.admindinder.com/api",
};

const bucketUrl = {
  local: "https://dinder-development.s3-eu-west-1.amazonaws.com/resources",
  development:
    "https://dinder-development.s3-eu-west-1.amazonaws.com/resources",
  production: "https://d2klls0ocy2up.cloudfront.net/resources",
};

const apiPlaces = "AIzaSyDSvq58NppCmUkZAkudbINrLKwBIwghMR8";

const roles = ["super_admin", "admin", "institution", "user"];

// CONFIG
const config = {
  apiUrl: apiUrl[env],
  bucketUrl: bucketUrl[env],
  apiPlaces: apiPlaces,
  roles: roles,
  locales: ["ca", "es", "en"],
  defaultLocale: "ca",
};

export default config;
